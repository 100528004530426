import React, { Component, useState } from 'react';
import { Container, Row, Col, Input, Button, Alert, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import '../styles/background_flip.css';
//import AdminFunctions from './admin/AdminFunctions';
import AuthFunctions from './services/AuthService';
import Session from './auth/Session';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                {/*<input*/}
                {/*  type="checkbox"*/}
                {/*  checked={props.isSelected}*/}
                {/*  onChange={() => null}*/}
                {/*/>{" "}*/}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


export class OrderAssessment extends Component {
    static displayName = OrderAssessment.name;

    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            userId: '',
            client: null,
            active: false,
            firstName: null,
            lastName: null,
            officePhone: null,
            ext: null,
            homePhone: null,
            mobilePhone: null,
            email: null,
            success: undefined,
            userAlreadyExists: false,
            clientRequired: false,
            user: '',
            assessmentType: '',
            userClient: '',
            assessmentLevels: [{ label: "Executive Appraisal", value: "EA" }, { label: "Management Assessment", value: "MA" }, { label: "Individual Contributor Assessment", value: "ICA" }],
            assessmentLevel: '',
            divisionOptions: '',
            divisionOption: '',
            candidateEmail: '',
            candidateName: '',
            poNumber: '',
            strengths: '',
            concerns: '',
            outstandingBehaviors: '',
            additionalComments: '',
            deadlines: '',
            requireSpecialHandling: false,
            invoiceInformation: '!!! THIS IS INVOICE INFORMATION !!!"',
            invoiceInformationRadio: '',
            newInvoiceInformation: '',
            feedbackCall: false,
            feedbackReport: false,
            coachingCard: false,
            additionalDeadlines: '',
            modal: false,
            setModal: false,
            resumeAttached: false,
            resumeFiles: null,
            priFiles: null,
            positionFiles: null,
            currentRole: '',
            newDivision: '',
            divisions: null,
            selectedResumeFile: null,
            selectedPositionJobDescriptionFile: null,
            selectedPriFile: null,
            isSending: false

        };
        document.getElementsByTagName('html')[0].className = "background-simple-flip";

    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    //currentAssessmentLevels() {
    //    if (client.clientCompany === "Bloomingdale's") {
    //        return assessmentLevelsBD;
    //    } else {
    //        return assessmentLevels;
    //    }
    //}

    handleAssessmentLevelChange = (selected) => {
        console.log(selected);
        this.setState({
            assessmentLevel: [selected]
        });
    };

    handleDivisionOptionChange = (selected) => {
        console.log(selected);
        this.setState({
            divisionOption: [selected]
        });
    };

    DisplayMessage(message, level) {
        alert(message);
    }
    isValid() {
        if (this.state.client == false) {
            this.DisplayMessage("Requestor's Client name is required.");
            return false;
        } else if (this.state.firstName == false) {
            this.DisplayMessage("Requestor's First Name is required.");
            return false;
        } else if (this.state.lastName == false) {
            this.DisplayMessage("Requestor's Last Name is required.");
            return false;
        } else if (this.state.email == false) {
            this.DisplayMessage("Requestor's Email is required.");
            return false;
        } else if (this.state.divisionOption == false && this.state.userClient.divisionEnabled) {
            this.DisplayMessage("Division / Location is required.");
            return false;
        } else if (this.state.poNumber == false && this.state.userClient.poEnabled) {
            this.DisplayMessage("PO # is required.");
            return false;
        } else if (this.state.assessmentLevel.length <= 0) {
            this.DisplayMessage("Assessment Level is required.");
            return false;
        } else if (this.state.candidateName == false) {
            this.DisplayMessage("Candidate's Name is required.");
            return false;
        } else if (this.state.candidateEmail == false) {
            this.DisplayMessage("Candidate's Email is required");
            return false;
        } else if (this.state.assessmentType == false) {
            this.DisplayMessage("Assessment Type is required");
            return false;
        } else if (this.state.requireSpecialHandling == true && (this.state.newSpecialHandling == '' || this.state.newSpecialHandling == null)) {
            this.DisplayMessage("Instructions for Special Handling is required");
            return false;
        } else if (this.state.invoiceInformationRadio == false && this.state.invoiceInformationRadio != 'Yes' && this.state.invoiceInformationRadio != 'No') {
            this.DisplayMessage("'Use this invoice information?' is a required field.");
            return false;
        } else if (this.state.invoiceInformationRadio == 'No' && (this.state.newInvoiceInformation == '' || this.state.newInvoiceInformation == null)) {
            this.DisplayMessage("New Invoice Information is required.");
            return false;
        } else if (this.state.assessmentType === "External for selection" && this.state.resumeFiles === null) {
            this.DisplayMessage("A Resume file is required for External for Selection.");
            return false;
        } else if ((this.state.assessmentType === "Internal for selection" || this.state.assessmentType === "Internal for development" || this.state.assessmentType === "External for selection" ) && this.state.positionFiles === null) {
            this.DisplayMessage("A Position file is required.");
            return false;
        } else if ((this.state.assessmentType === "Internal for selection" || this.state.assessmentType === "Internal for development") && this.state.priFiles === null) {
            this.DisplayMessage("A PRI file is required for Internal assessments.");
            return false;
        } else {
            return true;
        }
    }

    isInternal() {
        if (this.state.assessmentType === "Internal for selection" || this.state.assessmentType === "Internal for development") {
            return true;
        } else {
            return false;
        }
    }

    isChangeInvoiceInformation() {
        if (this.state.invoiceInformationRadio === "No") {
            return true;
        } else {
            return false;
        }
    }

    handleSendEmail() {

        if (this.isValid()) {
            var message = this.compileMessage();

            this.sendEmail(message);
        } else {
            console.log("Failed validation!!!");
        }

    }

    async checkAuthenticated() {
        var isAuthenticated = await AuthFunctions.getAuthenticatedAsync();
        if (!isAuthenticated) {
            this.props.history.push('/login');
        }
        this.setState({ user: isAuthenticated });
    }

    async componentDidMount() {
        await this.checkAuthenticated();

        if (this.state.user) {
            const isAdmin = await AuthFunctions.isAdminAsync();
            const isComplete = await AuthFunctions.isCompleteUserOrAboveAsync();
            this.getUserDetails();
            this.setState({ isAdmin: isAdmin, isComplete: isComplete });
            document.getElementsByTagName('html')[0].className = "background-navigation";
        }
        else {
            document.getElementsByTagName('html')[0].className = "background-title";
        }

        this.getClients();

        //}
        this.getClientByUserIdAsync();
        this.getPOEnabled();
    }

    render() {
        //Quick update to add the transparent to the select.  General CSS doesn't hit it sadly. CDC 5/19 
        const customStyles = {
            control: (base, state) => ({
                ...base,
                background: "transparent",
            })
        };


        return (
            <div>
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Order Assessment</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs='6'>
                            <Typeahead
                                id='client'
                                labelKey='company'
                                onChange={(selected) => this.setState({ client: selected })}
                                selected={this.state.client}
                                options={this.state.clientOptions}
                                placeholder='Search Client'
                                valid={this.state.client ? true : false}
                                invalid={!this.state.client ? true : false}
                                disabled={true}
                            />
                        </Col>
                        {/*<Col xs='2' style={{ marginLeft: '20px' }}>*/}
                        {/*    <Input*/}
                        {/*        type='checkbox'*/}
                        {/*        onChange={(e) => this.setState({ active: e.target.checked })}*/}
                        {/*        checked={this.state.active}*/}
                        {/*        name='active'*/}
                        {/*    />*/}
                        {/*    <label style={{ marginLeft: '10px' }}>Active</label>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                name='firstName'
                                id='firstName'
                                placeholder='First Name'
                                valid={this.state.firstName ? true : false}
                                invalid={!this.state.firstName ? true : false}
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                name='lastName'
                                id='lastName'
                                placeholder='Last Name'
                                valid={this.state.lastName ? true : false}
                                invalid={!this.state.lastName ? true : false}
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='email'
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                name='email'
                                id='email'
                                placeholder='Email'
                                valid={this.state.email ? true : false}
                                invalid={!this.state.email ? true : false}
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    {this.state.userClient.divisionEnabled === true && (
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs='4'>
                                <ReactSelect
                                    options={this.state.divisionOptions}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    components={{
                                        Option
                                    }}
                                    onChange={this.handleDivisionOptionChange}
                                    allowSelectAll={true}
                                    value={this.state.divisionOption}
                                    valid={this.state.divisionOption.length > 0 ? true : false}
                                    invalid={this.state.divisionOption.length === 0 ? true : false}
                                    placeholder='Choose Division / Location...'
                                />
                            </Col>
                            <Col xs='2' >
                                <Button color="primary" onClick={this.toggle}>
                                    Add a Division
                                </Button>
                                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                    <ModalHeader toggle={this.state.toggle}>Division</ModalHeader>
                                    <ModalBody>
                                        <p>
                                            Add a division.
                                        </p>
                                        <Input
                                            type='text'
                                            value={this.state.newDivision}
                                            name='newDivision'
                                            id='newDivision'
                                            placeholder='New Division'
                                            onChange={(e) => this.setState({ newDivision: e.target.value })}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        {/*<Button color="primary" onClick={this.toggle}>*/}
                                        {/*    Do Something*/}
                                        {/*</Button>{' '}*/}
                                        <Button color="primary" onClick={() => {
                                            this.saveClientDivisionsAsync(this);
                                            this.toggle();
                                        }}>
                                            Save
                                        </Button>
                                        <Button color="secondary" onClick={this.toggle}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </Col>
                            <Col xs='6'>
                                <label className="required">*</label>
                            </Col>
                        </Row>
                    )}
                    {this.state.userClient.poEnabled === true && (
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs='6'>
                                <Input
                                    type='text'
                                    value={this.state.poNumber}
                                    name='poNumber'
                                    id='poNumber'
                                    placeholder='PO #'
                                    onChange={(e) => this.setState({ poNumber: e.target.value })}
                                    valid={this.state.poNumber ? true : false}
                                    invalid={!this.state.poNumber ? true : false}
                                />
                            </Col>
                            <Col xs='6'>
                                <label className="required">*</label>
                            </Col>
                        </Row>
                    )}
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <ReactSelect
                                options={this.state.assessmentLevels}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={this.handleAssessmentLevelChange}
                                allowSelectAll={true}
                                value={this.state.assessmentLevel}
                                valid={this.state.assessmentLevel.length > 0 ? true : false}
                                invalid={this.state.assessmentLevel.length === 0 ? true : false}
                                placeholder='Choose Assessment Level...'
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.candidateName}
                                onChange={(e) => this.setState({ candidateName: e.target.value })}
                                name='candidateName'
                                id='candidateName'
                                placeholder='Candidate Name'
                                valid={this.state.candidateName ? true : false}
                                invalid={!this.state.candidateName ? true : false}
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='email'
                                value={this.state.candidateEmail}
                                onChange={(e) => this.setState({ candidateEmail: e.target.value })}
                                name='candidateEmail'
                                id='candidateEmail'
                                placeholder='Candidate Email'
                                valid={this.state.candidateEmail ? true : false}
                                invalid={!this.state.candidateEmail ? true : false}
                            />
                        </Col>
                        <Col xs='6'>
                            <label className="required">*</label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <label>Assessment Type: <label className="required">*</label></label>

                        <Col xs='4'>

                            <Input
                                type='radio'
                                value='External for selection'
                                onChange={() => this.setState({ assessmentType: "External for selection" })}
                                checked={this.state.assessmentType === "External for selection"}
                                name='assessmentType'
                            />
                            <label>&nbsp;External for selection</label>
                            <br />

                            <Input
                                type='radio'
                                value='Internal for selection'
                                onChange={() => this.setState({ assessmentType: "Internal for selection" })}
                                checked={this.state.assessmentType === "Internal for selection"}
                                name='assessmentType'
                            />
                            <label>&nbsp;Internal for selection</label>
                            <br />

                            <Input
                                type='radio'
                                value='Internal for development'
                                onChange={() => this.setState({ assessmentType: "Internal for development" })}
                                checked={this.state.assessmentType === "Internal for development"}
                                name='assessmentType'
                            />
                            <label>&nbsp;Internal for development</label>
                        </Col>
                    </Row>
                    {this.isInternal() === true && (
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs='6'>
                                <Input
                                    type='text'
                                    value={this.state.currentRole}
                                    name='currentRole'
                                    id='currentRole'
                                    placeholder='Current Role'
                                    onChange={(e) => this.setState({ currentRole: e.target.value })}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='textarea'
                                value={this.state.strengths}
                                name='strengths'
                                id='strengths'
                                placeholder='Strengths'
                                onChange={(e) => this.setState({ strengths: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='textarea'
                                value={this.state.concerns}
                                name='concerns'
                                id='concerns'
                                placeholder='Concerns'
                                onChange={(e) => this.setState({ concerns: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='textarea'
                                value={this.state.outstandingBehaviors}
                                name='outstandingBehaviors'
                                id='outstandingBehaviors'
                                placeholder='Outstanding Behaviors'
                                onChange={(e) => this.setState({ outstandingBehaviors: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='textarea'
                                value={this.state.additionalComments}
                                name='additionalComments'
                                id='additionalComments'
                                placeholder='Additional Comments'
                                onChange={(e) => this.setState({ additionalComments: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <label>Resume:
                            {this.state.assessmentType === "External for selection" && (
                                <label className="required">*</label>
                            )}
                        </label>
                        <Col xs='6'>
                            <input
                                type='file'
                                name='attachResume'
                                id='attachResume'
                                placeholder='Resume'
                                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => this.onResumeAdd(e)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <label>Position/Job Description:
                            {(this.state.assessmentType === "Internal for selection" || this.state.assessmentType === "Internal for development" || this.state.assessmentType === "External for selection") && (
                                <label className="required">*</label>
                            )}
                        </label>
                        <Col xs='6'>
                            <input
                                type='file'
                                name='positionJobDescription'
                                id='positionJobDescription'
                                placeholder='Position/Job Description'
                                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => this.onPositionJobDescriptionAdd(e)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <label>PRI:
                            {(this.state.assessmentType === "Internal for selection" || this.state.assessmentType === "Internal for development") && (
                                <label className="required">*</label>
                            )}
                        </label>
                        <Col xs='6'>
                            <input
                                type='file'
                                name='pri'
                                id='pri'
                                placeholder='PRI'
                                accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={(e) => this.onPriAdd(e)}
                            />
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs='2'>*/}
                    {/*        <Button onClick={() => this.onFileUpload()}>*/}
                    {/*            Upload*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}



                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <p>We will deliver this report as normal, unless you indicate that this candidate or role requires special handling (e.g., due to heightened confidentiality).</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>

                        <Col xs='6'>
                            <label>Require Special Handling: &nbsp;</label>

                            <Input
                                type='checkbox'
                                value=''
                                name='requireSpecialHandling'
                                id='requireSpecialHandling'
                                onChange={(e) => this.setState({ requireSpecialHandling: !this.state.requireSpecialHandling })}
                            />
                        </Col>
                    </Row>
                    {this.state.requireSpecialHandling === true && (
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs='6'>
                                <Input
                                    type='textarea'
                                    value={this.state.newSpecialHandling}
                                    name='newSpecialHandling'
                                    id='newSpecialHandling'
                                    placeholder='Please provide instructions for report delivery'
                                    onChange={(e) => this.setState({ newSpecialHandling: e.target.value })}
                                />
                            </Col>
                            <Col xs='6'>
                                <label className="required">*</label>
                            </Col>
                        </Row>
                    )}
                    <Row style={{ marginTop: '10px' }}>

                        <Col xs='6'>
                            <label>Please review invoicing information below and provide alternate instructions if needed.</label>
                            <br />
                            <label>{this.state.userClient.invoiceRecipients}</label>
                            {/*<Input*/}
                            {/*    type='checkbox'*/}
                            {/*    value=''*/}
                            {/*    name='invoiceInformation'*/}
                            {/*    id='invoiceInformation'*/}
                            {/*    onChange={(e) => this.setState({ invoiceInformation: e.target.value })}*/}
                            {/*/>*/}
                            <br />
                            <label>Use this invoice information? &nbsp;<label className="required">*</label></label>
                            <br />
                            <Input
                                type='radio'
                                value='Yes'
                                onChange={(e) => this.setState({ invoiceInformationRadio: e.target.value })}
                                name='invoiceInformation'
                            />
                            <label>&nbsp;Yes</label>
                            <br />
                            <Input
                                type='radio'
                                value='No'
                                onChange={(e) => this.setState({ invoiceInformationRadio: e.target.value })}
                                name='invoiceInformation'
                            />
                            <label>&nbsp;No</label>
                            <br />

                        </Col>
                    </Row>
                    {this.isChangeInvoiceInformation() === true && (
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs='6'>
                                <Input
                                    type='textarea'
                                    value={this.state.newInvoiceInformation}
                                    name='newInvoiceInformation'
                                    id='newInvoiceInformation'
                                    placeholder='New Invoice Information...'
                                    onChange={(e) => this.setState({ newInvoiceInformation: e.target.value })}
                                />
                            </Col>
                            <Col xs='6'>
                                <label className="required">*</label>
                            </Col>
                        </Row>
                    )}
                    <Row style={{ marginTop: '10px' }}>

                        <Col xs='6'>
                            <label>Feedback Call: &nbsp;</label>

                            <Input
                                type='checkbox'
                                value=''
                                name='feedbackCall'
                                id='feedbackCall'
                                onChange={(e) => this.setState({ feedbackCall: !this.state.feedbackCall })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>

                        <Col xs='6'>
                            <label>Feedback Report: &nbsp;</label>

                            <Input
                                type='checkbox'
                                value=''
                                name='feedbackReport'
                                id='feedbackReport'
                                onChange={(e) => this.setState({ feedbackReport: !this.state.feedbackReport })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>

                        <Col xs='6'>
                            <label>Coaching Card: &nbsp;</label>

                            <Input
                                type='checkbox'
                                value=''
                                name='coachingCard'
                                id='CoachingCard'
                                onChange={(e) => this.setState({ coachingCard: !this.state.coachingCard })}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.additionalDeadlines}
                                name='additionalDeadlines'
                                id='additionalDeadlines'
                                placeholder='Are there any deadlines we should be aware of?'
                                onChange={(e) => this.setState({ additionalDeadlines: e.target.value })}
                            />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '20px' }}>
                        <Col xs='2'>
                            <Button
                                color='primary'
                                onClick={() => this.handleSendEmail()}
                                disabled={this.state.isSending}

                            >
                                Send
                            </Button>
                            {this.state.isSending && (
                                <img src="/ajax-loader.gif" />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    async getClients() {
        const token = Session.getToken();
        const response = await fetch('api/Clients/GetClientDropdown', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ clientOptions: data });
    }

    async getUserDetails() {
        const token = Session.getToken();
        const response = await fetch(`api/Users/GetUserDetails`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        console.log(data);

        const client = [
            {
                id: data.clientId,
                company: data.client
            }
        ];
        this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            officePhone: data.officePhone,
            ext: data.ext,
            homePhone: data.homePhone,
            mobilePhone: data.mobilePhone,
            active: data.active,
            client: data.clientId === 0 ? null : client,
        })
    }

    async getClientByUserIdAsync() {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientByUserId`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ userClient: data });
        this.getClientDivisionsAsync(this.state.userClient.id);
        this.setAssessmentLevels();
    }

    isBloomingdales() {
        return this.state.userClient.company === "Bloomingdale's";
    }
    async setAssessmentLevels() {
        if (this.state.userClient.levelEA || this.state.userClient.levelMA || this.state.userClient.levelICA) {
            var assessmentLevel = [];
            //            assessmentLevels: [{ label: "EA", value: "EA" }, { label: "MA", value: "MA" }, { label: "ICA", value: "ICA" }],
            if (this.state.userClient.levelEA) {
                assessmentLevel.push({ label: "Executive Appraisal", value: "EA" });
            }
            if (this.state.userClient.levelMA) {
                assessmentLevel.push({ label: "Management Assessment", value: "MA" });
            }
            if (this.state.userClient.levelICA && !this.isBloomingdales()) {
                assessmentLevel.push({ label: "Individual Contributor Assessment", value: "ICA" });
            } else if (this.state.userClient.levelICA && this.isBloomingdales()) {
                assessmentLevel.push({ label: "Human Resources Evaluation", value: "HRE" });
            }
            this.setState({ assessmentLevels: assessmentLevel });
        } else {
            var assessmentLevel = [{ label: "Executive Appraisal", value: "EA" }];
            this.setState({ assessmentLevels: assessmentLevel });
        }

        if (this.state.assessmentLevels.length === 1) {
            this.setState({ assessmentLevel: this.state.assessmentLevels });
        }
    }

    async getClientDivisionsAsync(id) {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/GetClientDivisionsAsync?id=${id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ divisions: data });
        this.getDivisionOptions();
    }

    async saveClientDivisionsAsync(modal) {
        const token = Session.getToken();
        const response = await fetch(`api/Clients/SaveClientDivisionsAsync?id=${this.state.userClient.id}&newDivision=${this.state.newDivision}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ divisions: data });
        this.getDivisionOptions();
    }

    async sendEmail(message) {
        try {
            this.setState({ isSending: true });
            const formData = new FormData();

            //if (this.state.selectedResumeFile) {
            //    formData.append("FormFile", this.state.selectedResumeFile);
            //    formData.append("FileName", this.state.selectedResumeFile.name);
            //    formData.append("RecordId", this.state.userClient.recordId);
            //}

            if (this.state.selectedResumeFile) {
                formData.append("FormFileResume", this.state.selectedResumeFile);
                formData.append("FileNameResume", this.state.selectedResumeFile.name);
                formData.append("RecordIdResume", this.state.userClient.recordId);
            }

            if (this.state.selectedPositionJobDescriptionFile) {
                formData.append("FormFilePosition", this.state.selectedPositionJobDescriptionFile);
                formData.append("FileNamePosition", this.state.selectedPositionJobDescriptionFile.name);
                formData.append("RecordIdPosition", this.state.userClient.recordId);
            }

            if (this.state.selectedPriFile) {
                formData.append("FormFilePri", this.state.selectedPriFile);
                formData.append("FileNamePri", this.state.selectedPriFile.name);
                formData.append("RecordIdPri", this.state.userClient.recordId);
            }

            formData.append("Message", message);

            //const formDataResume = new FormData();
            //if (this.state.resumeFiles) {
            //    messageText.Resume = new FormData();
            //    messageText.Resume.append("formFile", this.state.selectedResumeFile);
            //    messageText.Resume.append("fileName", this.state.selectedResumeFile.name);
            //    messageText.Resume.append("RecordId", this.state.userClient.recordId);
            //    messageText.Resume.append("ClientCompany", this.state.userClient.company);
            //    console.log(messageText.Resume);
            //}

            ////const formDataPosition = new FormData();
            //if (this.state.positionFiles) {
            //    messageText.Position = new FormData();
            //    messageText.Position.append("formFile", this.state.selectedPositionJobDescriptionFile);
            //    messageText.Position.append("fileName", this.state.selectedPositionJobDescriptionFile.name);
            //    messageText.Position.append("RecordId", this.state.userClient.recordId);
            //    messageText.Position.append("ClientCompany", this.state.userClient.company);
            //    console.log(messageText.Position);
            //}


            //if (this.state.priFiles) {
            //    messageText.Pri = new FormData();
            //    messageText.Pri.append("formFile", this.state.selectedPriFile);
            //    messageText.Pri.append("fileName", this.state.selectedPriFile.name);
            //    messageText.Pri.append("RecordId", this.state.userClient.recordId);
            //    messageText.Pri.append("ClientCompany", this.state.userClient.company);
            //    console.log(messageText.Pri);
            //}

            //messageText = { 'Message': message, 'Resume': this.state.selectedResumeFile, 'Position': this.state.selectedPositionJobDescriptionFile, 'Pri': this.state.selectedPriFile };


            const token = Session.getToken();

            //const response = await fetch(`api/Clients/SendEmail`, {
            //    headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json'},
            //    method: 'POST',
            //    body: JSON.stringify(messageText)
            //});

            await fetch(`api/Clients/SendEmail`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST',
                body: formData
            }).then(function (o) {
                if (o.status === 200) {
                    alert("Order submitted");
                    window.location.href = "/";
                } else {
                    console.log('did not receive an OK!');
                    alert("An error occurred!  Please try again.");
                }
                this.setState({ isSending: false });
            });
            //const data = await response.json();
            //this.onFileUpload();
        } catch (error) {
            this.setState({ isSending: false });
            throw error;
        } finally {
            
        }
    }

    compileMessage() {
        var message = `
            <h1>Assessment Order</h1>
            <h3><u>Company Information</u></h3>
            <p>
            Company Name: ${this.state.userClient.company} <br/>
            Request Submitted by: ${this.state.firstName} ${this.state.lastName} <br/>
            Email Address: ${this.state.email} <br/>
            ${this.state.divisionOption ? "Division: " : ""} ${this.state.divisionOption[0] ? this.state.divisionOption[0].value : ""} <br />
            ${this.state.poNumber ? "PO Number: " : ""} ${this.state.poNumber ? this.state.poNumber : ""}<br/>
            </p>
            <h3><u>Candidate Information</u></h3>
            <p>
            Assessment Level: ${this.state.assessmentLevel[0] ? this.state.assessmentLevel[0].value : ""} <br/>
            Candidate Name: ${this.state.candidateName} <br/>
            Candidate Email: ${this.state.candidateEmail} <br/>
            Assessment Type: ${this.state.assessmentType} <br/>
            ${this.state.currentRole ? "Current Role: " : ""} ${this.state.currentRole ? this.state.currentRole : "N/A"} <br/>
            Strengths: ${this.state.strengths} <br/>
            Concerns: ${this.state.concerns} <br/>
            Outstanding Behaviors: ${this.state.outstandingBehaviors} <br/>
            Additional Comments: ${this.state.additionalComments} <br/>
            ${this.state.selectedResumeFile ? "Resume: " : ""} ${this.state.selectedResumeFile ? this.state.selectedResumeFile.name : ""} <br/>
            ${this.state.selectedPositionJobDescriptionFile ? "Position/Job Description: " : ""} ${this.state.selectedPositionJobDescriptionFile ? this.state.selectedPositionJobDescriptionFile.name : ""} <br/>
            ${this.state.selectedPriFile ? "PRI: " : ""} ${this.state.selectedPriFile ? this.state.selectedPriFile.name : ""}<br/>
            </p>
            <h3><u>Confidential Requests</u></h3>
            <p>
            Requires Special Handling: ${this.state.requireSpecialHandling ? 'Yes' : 'No'} <br/>
            ${this.state.requireSpecialHandling ? "Special Handling Instructions: " + this.state.newSpecialHandling : ""} <br/>
            Invoice Information:<br/> ${this.state.newInvoiceInformation && this.state.invoiceInformationRadio == 'No' ? this.state.newInvoiceInformation : this.state.userClient.invoiceRecipients}<br/>
            </p>
            <h3><u>Additional Services</u></h3>
            <p>
            Feedback Call: ${this.state.feedbackCall ? 'Yes' : 'No'}<br/>
            Feedback Report: ${this.state.feedbackReport ? 'Yes' : 'No'}<br/>
            Coaching Card: ${this.state.coachingCard ? 'Yes' : 'No'}</br>
            Additional Deadlines: ${this.state.additionalDeadlines}</br>
            </p>
            `;
        return message;
    }

    getPOEnabled() {
        for (let i = 0; i < this.state.clientOptions.length; i++) {
            if (this.state.clientOptions[i].id === this.state.client[0].id) {
                this.setState({ userClient: this.state.clientOptions[i] });
            }
        }
    }

    getDivisionOptions() {
        //[{ label: "EA", value: "EA" }, { label: "MA", value: "MA" }, { label: "ICA", value: "ICA" }],
        let options = [];
        for (let i = 0; i < this.state.divisions.length; i++) {
            options.push({ label: this.state.divisions[i].divisionName, value: this.state.divisions[i].divisionName });
        }

        this.setState({ divisionOptions: options });
    }

    async onFileUpload() {
        console.log(this.state.userClient.recordId);
        //Do we have a client selected?
        if (this.state.userClient.recordId > 0) {
            // Create an object of formData
            //this.state.uploadSuccess = "";
            if (this.state.resumeFiles) {
                const formData = new FormData();

                formData.append("formFile", this.state.selectedResumeFile);
                formData.append("fileName", this.state.selectedResumeFile.name);
                formData.append("RecordId", this.state.userClient.recordId);
                formData.append("ClientCompany", this.state.userClient.company);

                // Details of the uploaded file 
                console.log(this.state.selectedResumeFile);
                console.log(formData.formFile);

                // Request made to the backend api 
                // Send formData object 
                const token = Session.getToken();
                console.log("Token: " + token);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${token}` },
                    body: formData
                };
                console.log(requestOptions);
                const response = await fetch('api/Clients/UploadOrderAssessmentFiles', requestOptions);
                //fetch('api/Clients/UploadFile', requestOptions);\
                console.log(response);
                console.log(this.state.userClient.recordId);
            }

            if (this.state.positionFiles) {
                const formData = new FormData();

                formData.append("formFile", this.state.selectedPositionJobDescriptionFile);
                formData.append("fileName", this.state.selectedPositionJobDescriptionFile.name);
                formData.append("RecordId", this.state.userClient.recordId);
                formData.append("ClientCompany", this.state.userClient.company);

                // Details of the uploaded file 
                console.log(formData);

                // Request made to the backend api 
                // Send formData object 
                const token = Session.getToken();
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${token}` },
                    body: formData
                };
                const response = await fetch('api/Clients/UploadOrderAssessmentFiles', requestOptions);
            }

            if (this.state.priFiles) {
                const formData = new FormData();

                formData.append("formFile", this.state.selectedPriFile);
                formData.append("fileName", this.state.selectedPriFile.name);
                formData.append("RecordId", this.state.userClient.recordId);
                formData.append("ClientCompany", this.state.userClient.company);

                // Details of the uploaded file 
                console.log(formData);

                // Request made to the backend api 
                // Send formData object 
                const token = Session.getToken();
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${token}` },
                    body: formData
                };
                const response = await fetch('api/Clients/UploadOrderAssessmentFiles', requestOptions);
            }
            //If we made it here all files uploaded!

        } else {
            this.setState({ uploadSuccess: "clientRequired" });
            console.log("Failed");
        }
    };

    onResumeFileChange = event => {
        // Update the state 
        this.setState({ selectedResumeFile: event.target.files[0] });
    };

    onPositionJobDescriptionFileChange = event => {
        // Update the state 
        this.setState({ selectedPositionJobDescriptionFile: event.target.files[0] });
    };

    onPriFileChange = event => {
        // Update the state 
        this.setState({ selectedPriFile: event.target.files[0] });
    };

    onResumeAdd(e) {
        this.onResumeFileChange(e);
        this.setState({ resumeFiles: e.target.files[0] });
    }

    onPositionJobDescriptionAdd(e) {
        this.onPositionJobDescriptionFileChange(e);
        this.setState({ positionFiles: e.target.files[0] });
    }

    onPriAdd(e) {
        this.onPriFileChange(e);
        this.setState({ priFiles: e.target.files[0] });
    }
}